import Siema from 'siema';

var sectionInserted = false;
var newsSlider;

if(document.querySelector('.cb-main-wrap > .editoria') || document.querySelector('.content-materia')){
    let header = document.querySelector('.main-col > .menu');
    
    let editoria = document.createElement('a');
    //editoria.innerText = 'Editoria';
    //editoria.setAttribute('class', 'editoria-title');

    //header.appendChild(editoria);

    // let observerHeader = new IntersectionObserver(
    //     (entries, observer) => {
    //         entries.forEach(entry => {
    //             // if(!entry.isIntersecting){
    //             //     document.querySelector('.header-alt > .main').style.boxShadow = '0 4px 4px 0 rgba(0, 0, 0, 0.24)';
    //             // } else {
    //             //     document.querySelector('.header-alt > .main').style.boxShadow = 'none';
    //             // }
    //         });
    //     }
    // );

    // document.querySelectorAll('.cb-main-wrap > nav').forEach(item => { observerHeader.observe(item) });

    let observerCBContent = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
                if(!entry.isIntersecting){
                    // if( Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 600){
                    //     document.querySelector('.menu > .cb').style.display = 'none';
                    //     document.querySelector('.menu > .cb-initials').style.display = 'block';
                    // }
                    // document.querySelector('.menu > .cb').style.width = '0';

                    if(document.querySelector('.content-materia')){
                        let a = document.createElement('span');
                        // a.setAttribute('href', '#');
                        a.classList.add('header-materia-title');
                        a.innerText = document.querySelector('.materia-title > h1').innerText;
                        document.querySelector('.menu').appendChild(a);

                        // window.onwheel = (event) => {
                        //     if(event.deltaY < 0){
                        //         if(!sectionInserted) {
                        //             sectionInserted = true;

                        //             let section = document.createElement('section');
                                    
                        //             // let prevBtn = document.querySelector('.prev').cloneNode(true);
                        //             // prevBtn.style.display = 'block';

                        //             // let nextBtn = document.querySelector('.next').cloneNode(true);
                        //             // nextBtn.style.display = 'block';

                        //             for(let i = 0; i < 20; i++){
                        //                 const img = document.createElement('img');
                        //                 img.setAttribute('src', 'https://picsum.photos/100/66');
                                        
                        //                 const h5  = document.createElement('h5')
                        //                 h5.innerText = "Coronavírus: 'Respeitem a dor', diz nora de mulher que morreu em BH";
                                        
                        //                 let a = document.createElement('a')
                        //                 a.append(img, h5);
                        //                 a.setAttribute('href', '#');
                                        
                        //                 section.appendChild(a);
                        //             }
                                    
                        //             let divMain = document.createElement('div');
                        //             divMain.classList.add('wrapNews');
                        //             document.querySelector('.main').appendChild(divMain);
                        //             document.querySelector('.main > div.wrapNews').appendChild(section);

                        //             newsSlider = new Siema({
                        //                 selector: section,
                        //                 duration: 200,
                        //                 easing: 'ease-out',
                        //                 perPage: {
                        //                     0: 2,
                        //                     600: 3,
                        //                     1024: 4, 
                        //                     1366: 5,
                        //                     1990: 6,
                        //                 },
                        //                 startIndex: 0,
                        //                 draggable: true,
                        //                 multipleDrag: true,
                        //                 threshold: 20,
                        //                 loop: true,
                        //                 rtl: false,
                        //                 onInit: () => {},
                        //                 onChange: () => {},
                        //             });

                        //             // section.appendChild(prevBtn);
                        //             // section.appendChild(nextBtn);

                        //             // prevBtn.onclick = () => { newsSlider.prev(); }
                        //             // nextBtn.onclick = () => { newsSlider.next(); }
                        //         }
                        //     } else {
                        //         if(sectionInserted){
                        //             const main = document.querySelector('.header-alt > .main');
                        //             main.removeChild(document.querySelector('.header-alt > .main > .wrapNews'));
                        //             sectionInserted = false;
                        //         }
                        //     }
                        // }
                    }
                } else {
                    // document.querySelector('.menu > .cb-initials').style.display = 'none';
                    
                    if(document.querySelector('.content-materia') && document.querySelector('.menu').children.length >= 3){
                        let list = document.querySelector('.menu');
                        document.querySelector('.menu').removeChild(list.lastElementChild);
                        
                        const main = document.querySelector('.header-alt > .main');
                        // main.removeChild(document.querySelector('.header-alt > .main > .wrapNews'));
                        sectionInserted = false;
                    }
                    if( Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 600){
                        document.querySelector('.menu > .cb').style.display = 'block';
                    }
                    // setTimeout(() => {
                    //     document.querySelector('.menu > .cb').style.width = '300px';
                    // }, 50);

                    window.onwheel = null;
                }
            });
        }
    );

    if(document.querySelector('.cb-main-wrap > .editoria')){
        let content = document.querySelector('.cb-main-wrap > .cb-content');
        // content.style.paddingTop = '55px';

        document.querySelectorAll('.cb-content').forEach(item => { observerCBContent.observe(item) });
    }

    if(document.querySelector('.content-materia')){
        let content = document.querySelector('.cb-main-wrap > .title');
        // content.style.paddingTop = '55px';

        document.querySelectorAll('.title > .content-title > .wrapper > .materia-title > h1').forEach(item => { observerCBContent.observe(item) });
    }
}