window.onload = () => {
    const contato = document.querySelector('.contato-container');
    const fieldsets = document.querySelectorAll('fieldset.form-group');
    
    if(contato){
        fieldsets.forEach(fieldset => {
            if(fieldset.children[0].nodeName === 'INPUT' ||
                fieldset.children[0].nodeName === 'TEXTAREA'){
                
                fieldset.onchange = (e) => {
                    if(e.target.value !== ''){
                        fieldset.children[1].setAttribute('style', 'color: transparent')
                    } else {
                        fieldset.children[1].removeAttribute('style');
                    }
                }
            }
        });

        const selects = document.querySelectorAll('fieldset.form-group > select');
        selects.forEach(select => {
            select.setAttribute('style', 'color: rgba(0, 0, 0, 0.3');
            select.onchange = (e) => {
                if(e.target.value === '0' ||
                    e.target.value === '0'){
                    select.setAttribute('style', 'color: rgba(0, 0, 0, 0.3)')
                    select.children.forEach(child => {
                        child.setAttribute('style', 'color: black');
                    })
                } else {
                    select.removeAttribute('style');
                }
            }
        })
    }
}