import Siema from 'siema';

const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

let specialContainer = document.querySelector('.cb-special-contents');

if(specialContainer && vw <= 600){
    let specialItemsContainer = document.querySelector('.cb-box-content');

    let specialContents = new Siema({
        selector: specialItemsContainer,
        duration: 200,
        easing: 'ease-out',
        perPage: 1,
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: true,
        rtl: false,
        onInit: () => {},
        onChange: () => {},
    });

    // let prevBtn = document.querySelector('.prev').cloneNode(true);
    // prevBtn.style.display = 'block';

    // let nextBtn = document.querySelector('.next').cloneNode(true);
    // nextBtn.style.display = 'block';

    // specialContainer.append(prevBtn, nextBtn);

    // prevBtn.onclick = () => { specialContents.prev(); }
    // nextBtn.onclick = () => { specialContents.next(); }
}