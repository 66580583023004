window.onload = function(){
    var body = document.querySelector("body");

    // Define a abertura do modal
    let buscaBtns = document.querySelectorAll('.search');
    // busca.addEventListener('click', function(){
    //     document.getElementById('modal').style.display = 'block';
    //     body.classList.add('block-scroll')
    // }, false)
    if(buscaBtns != null){
        buscaBtns.forEach(buscaBtn => {
            buscaBtn.onclick = function(){
                document.getElementById('modal').style.display = 'block';
                body.classList.add('block-scroll')
            }
        });
    }

    // Define o fechamento do modal
    let fecharBusca = document.querySelector('.modal-close');
    // fecharBusca.addEventListener('click', function(){
    //     var modal = fecharBusca.closest('.modal');
    //     modal.style.display = 'none';
    //     body.classList.remove('block-scroll');
    // }, false)
    if(fecharBusca != null){
        fecharBusca.onclick = function() {
            var modal = fecharBusca.closest('.modal');
            modal.style.display = 'none';
            body.classList.remove('block-scroll');
        }
    }
}()

window.onclick = function(event) {
    if(event.target.className === 'modal'){
        event.target.style.display = "none";
        document.querySelector("body").classList.remove('block-scroll');
    }
}
